import * as React from "react"
import Layout from "../components/layout"
import { addUtmTagsInUrls, setCookieFromUrl } from "../widgets/cookie"

export default function PrivacyPolicy(props: any) {
  const p = {
    ...props,
    title: "Privacy Policy:- OPA",
    description: "OPA Privacy Policy",
  }
  React.useEffect(() => {
    setCookieFromUrl()
  }, [1])
  React.useEffect(() => {
    addUtmTagsInUrls({ links: document.querySelectorAll("a") })
  }, [])
  return (
    <Layout {...p}>
      <div className="mainContainer">
        <div className="container">
          <section className="case-studies-hero height-box">
            <div className="case-studies-hero-top">
              <h1 style={{ visibility: "hidden" }}>OPA</h1>
              <h2>Privacy Policy</h2>
              <p>
                OPA Marketing Tech Pvt Ltd built the OPA app as a Free app. The
                App is owned by OPA Marketing Tech Pvt Ltd (referred as 'OPA'
                hereafter) a company registered in India under the registration
                number 354110 and having its registered office at F-110/111
                Moongipa Arcade, DN Nagar, Andheri West, Mumbai - 400053. This
                SERVICE is provided by OPA at no cost and is intended for use as
                is.
              </p>

              <p>
                This page is used to inform visitors regarding our policies with
                the collection, use, and disclosure of Personal Information if
                anyone decided to use our Service.
              </p>

              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </p>

              <p>
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which is accessible at OPA unless
                otherwise defined in this Privacy Policy.
              </p>

              <h4>Information Collection and Use</h4>
              <p>
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information, including but not limited to name, phone number,
                email id, pincode, date of birth, marital status, parental
                status, kids' age and gender, pet ownership and type,
                profession, social profiles. The information that we request
                will be retained by us and used as described in this privacy
                policy.
              </p>
              <p>
                The app does use third party services that may collect
                information used to identify you.
              </p>
              <p>
                Link to privacy policy of third party service providers used by
                the app:
              </p>
              <ul
                className="privacy-policy-item-list"
                style={{ display: "block" }}
              >
                <li>
                  <a
                    href="https://www.google.com/policies/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Google Play Services
                  </a>
                </li>
                <li>
                  <a
                    href="https://support.google.com/admob/answer/6128543?hl=en"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    AdMob
                  </a>
                </li>
                <li>
                  <a
                    href="https://firebase.google.com/policies/analytics"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Google Analytics for Firebase
                  </a>
                </li>
                <li>
                  <a
                    href="https://firebase.google.com/support/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Firebase Crashlytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/about/privacy/update/printable"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Fabric
                  </a>
                </li>
                <li>
                  <a
                    href="https://matomo.org/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Matomo
                  </a>
                </li>
                <li>
                  <a
                    href="https://clicky.com/terms#privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Clicky
                  </a>
                </li>
                <li>
                  <a
                    href="https://privacy.oath.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Flurry Analytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.appodeal.com/home/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Appodeal
                  </a>
                </li>
                <li>
                  <a
                    href="https://usefathom.com/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Fathom Analytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://unity3d.com/legal/privacy-policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Unity
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.sdkbox.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    SDKBOX
                  </a>
                </li>
                <li>
                  <a
                    href="https://gameanalytics.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    GameAnalytics
                  </a>
                </li>
                <li>
                  <a
                    href="https://onesignal.com/privacy_policy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    One Signal
                  </a>
                </li>
                <li>
                  <a
                    href="https://expo.io/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Expo
                  </a>
                </li>
                <li>
                  <a
                    href="https://sentry.io/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Sentry
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.applovin.com/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    AppLovin
                  </a>
                </li>
                <li>
                  <a
                    href="https://vungle.com/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Vungle
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.startapp.com/privacy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    StartApp
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.adcolony.com/privacy-policy/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    AdColony
                  </a>
                </li>
                <li>
                  <a
                    href="https://exotel.com/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Exotel
                  </a>
                </li>
                <li>
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Google
                  </a>
                </li>
              </ul>
            </div>
            <div style={{ float: "left" }}>
              <h5>Facebook</h5>
              <ul>
                <li>
                  API Client (OPA) uses Facebook and Instagram API Services to
                  verify if the Instagram handle belongs to the user
                </li>
                <li>
                  By using OPA app, users are agreeing to be bound by the
                  <a
                    href="https://help.instagram.com/581066165581870/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Instagram Terms of Service
                  </a>
                </li>
                <li>
                  By clicking on 'Verify Instagram Handle', user agrees to our
                  privacy policy and
                  <a
                    href="https://developers.facebook.com/terms#privacypolicy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Instagram Privacy Policy
                  </a>
                </li>
                <li>
                  Users can revoke API Client's access to their data via the
                  steps mentioned on this page -
                  <a
                    href="https://help.instagram.com/1144624522593085"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    https://help.instagram.com/1144624522593085
                  </a>
                </li>
                <li>
                  Alternatively, users can request deactivation of their opa
                  account via "settings" &gt; "Deactivate Profile" for deletion
                  of user data.
                </li>
              </ul>
              <p></p>

              <h5>YouTube API Services</h5>
              <ul>
                <li>
                  API Client uses{" "}
                  <a
                    href="https://www.youtube.com/t/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Youtube API.
                  </a>{" "}
                  Users can revoke that API Client's access to their data via
                  the Google security{" "}
                  <span>
                    <a
                      href="https://security.google.com/settings/security/permissions"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      settings page
                    </a>
                  </span>
                </li>
                <li>
                  API Client uses YouTube API to verify if the YouTube channel
                  belongs to the user. Users can revoke that API Client's access
                  to their data via the Google security{" "}
                  <a
                    href="https://security.google.com/settings/security/permissions"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    settings page
                  </a>
                </li>
                <li>
                  Users can revoke that API Client's access to their data via
                  the Google security{" "}
                  <span>
                    <a
                      href="https://security.google.com/settings/security/permissions"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      settings page
                    </a>
                  </span>
                </li>
                <li>
                  By using OPA app, users are agreeing to be bound by the
                  <a
                    href="https://www.youtube.com/t/terms"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    YouTube Terms of Service
                  </a>
                </li>
                <li>
                  By clicking on 'Verify YouTube Channel', user agrees to our
                  privacy policy and{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    Google Privacy Policy
                  </a>
                </li>
                <li>
                  Users can revoke API Client's access to their data via the{" "}
                  <a
                    href="https://security.google.com/settings/security/permissions"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    Google security settings page
                  </a>
                </li>
              </ul>

              <p></p>
              <h5>Log Data</h5>
              <ul>
                <li>
                  We want to inform you that whenever you use our Service, in a
                  case of an error in the app we collect data and information
                  (through third party products) on your phone called Log Data.
                  This Log Data may include information such as your device
                  Internet Protocol (“IP”) address, device name, operating
                  system version, the configuration of the app when utilizing
                  our Service, the time and date of your use of the Service, and
                  other statistics.
                </li>
              </ul>

              <p></p>
              <h5>Cookies</h5>
              <ul>
                <li>
                  Cookies are files with a small amount of data that are
                  commonly used as anonymous unique identifiers. These are sent
                  to your browser from the websites that you visit and are
                  stored on your device's internal memory.
                </li>
                <li>
                  This Service does not use these “cookies” explicitly. However,
                  the app may use third party code and libraries that use
                  “cookies” to collect information and improve their services.
                  You have the option to either accept or refuse these cookies
                  and know when a cookie is being sent to your device. If you
                  choose to refuse our cookies, you may not be able to use some
                  portions of this Service.
                </li>
              </ul>

              <p></p>
              <h5>Service Providers</h5>
              <ul>
                <li>
                  We may employ third-party companies and individuals due to the
                  following reasons:
                  <ul style={{ marginLeft: "20px" }}>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                  </ul>
                </li>
                <li>
                  We want to inform users of this Service that these third
                  parties have access to your Personal Information. The reason
                  is to perform the tasks assigned to them on our behalf.
                  However, they are obligated not to disclose or use the
                  information for any other purpose.
                </li>
              </ul>

              <p></p>
              <h5>Security</h5>
              <ul>
                <li>
                  We value your trust in providing us your Personal Information,
                  thus we are striving to use commercially acceptable means of
                  protecting it. But remember that no method of transmission
                  over the internet, or method of electronic storage is 100%
                  secure and reliable, and we cannot guarantee its absolute
                  security.
                </li>
              </ul>

              <p></p>
              <h5>Links to Other Sites</h5>
              <ul>
                <li>
                  This Service may contain links to other sites. If you click on
                  a third-party link, you will be directed to that site. Note
                  that these external sites are not operated by us. Therefore,
                  we strongly advise you to review the Privacy Policy of these
                  websites. We have no control over and assume no responsibility
                  for the content, privacy policies, or practices of any
                  third-party sites or services.
                </li>
              </ul>

              <p></p>
              <h5>Children’s Privacy</h5>
              <ul>
                <li>
                  These Services do not address anyone under the age of 13. We
                  do not knowingly collect personally identifiable information
                  from children under 13. In the case we discover that a child
                  under 13 has provided us with personal information, we
                  immediately delete this from our servers. If you are a parent
                  or guardian and you are aware that your child has provided us
                  with personal information, please contact us so that we will
                  be able to do necessary actions.
                </li>
              </ul>

              <p></p>
              <h5>Changes to This Privacy Policy</h5>
              <ul>
                <li>
                  We may update our Privacy Policy from time to time. Thus, you
                  are advised to review this page periodically for any changes.
                  We will notify you of any changes by posting the new Privacy
                  Policy on this page. This policy is effective as of 2020-06-20
                </li>
              </ul>

              <p></p>
              <h5>Contact Us</h5>
              <ul>
                <li>
                  If you have any questions or suggestions about our Privacy
                  Policy, do not hesitate to contact us at
                  support@opa.marketing.
                </li>
              </ul>
              <p></p>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}
// export const query = graphql`
//   {
//     allContentfulBlogPost {
//       edges {
//         node {
//           id
//           title
//         }
//       }
//     }
//   }
// `
