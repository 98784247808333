export const setCookieFromUrl = () => {
  if (typeof window === "undefined" || !window.document) return
  const urlParams = new URLSearchParams(window.location.search)
  //if utm string includes in key name then set cookie Ex. utm is substring of utm_source
  urlParams.forEach((value, key) => {
    if (key.includes("utm")) {
      document.cookie = `${key}=${value}; path=/`
    }
  })
}

export const getUtmFromCookie = () => {
  setCookieFromUrl()
  const utm = {}
  if (typeof window === "undefined" || !window.document) return
  const cookies = document.cookie.split(";")
  cookies.forEach((cookie) => {
    const [key, value] = cookie.split("=").map((e) => e.trim())
    if (key.includes("utm")) {
      utm[key] = value.trim()
    }
  })
  console.log(utm, cookies)
  return utm
}

export function addUtmTagsInUrls({
  links,
}: {
  links: NodeListOf<HTMLAnchorElement>
}) {
  for (let linkIndex = 0; linkIndex < links.length; linkIndex++) {
    links[linkIndex].href = decorateUrl(links[linkIndex].href)
  }

  // decorates the URL with query params
  function decorateUrl(urlToDecorate: string) {
    const utm = getUtmFromCookie()
    console.log(utm)
    if (Object.keys(utm).length > 0 && urlToDecorate) {
      const url = new URL(urlToDecorate)
      Object.keys(utm).forEach((key) => {
        url.searchParams.set(key, utm[key])
      })
      urlToDecorate = url.toString()
    }
    return urlToDecorate
  }
}
